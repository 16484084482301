import React from 'react';
import Layout from 'components/shared/layout';
import Breadcrumb from 'components/shared/breadcrumb';
import { graphql } from 'gatsby';
import SectionContainer from 'components/shared/section-container';
import Hat from 'components/shared/hat';
import SectionTitle from 'components/shared/section-title';
import theme from 'src/shared/theme';
import { pageSideMargin } from 'src/shared/styles/page-margin';
import styled from 'styled-components';
import { Icon as MaterialIcon } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { SM, XL, XS } from 'src/shared/media-query';
import TileButton from 'components/shared/tile-button';

const Content = styled.div`
  ${pageSideMargin};
  margin-top: ${theme.spacing(16)};
  margin-bottom: ${theme.spacing(22)};
`;

const Icon = styled(MaterialIcon)`
  display: inline-block;
`;

const LinkWrapper = styled(Link)`
  margin: ${theme.spacing(1)};
  width: 42vw;
  height: 42vw;

  @media ${XS} {
    margin: ${theme.spacing(4)};
    width: 14rem;
    height: 14rem;
  }

  @media ${SM} {
    width: 21.25rem;
    height: 21.25rem;
  }

  @media ${XL} {
    margin: ${theme.spacing(8)} 0;
  }
`;

const TileButtons = styled.div`
  display: flex;
  margin-top: ${theme.spacing(16)};
  flex-wrap: wrap;
  justify-content: center;

  @media ${XL} {
    justify-content: space-between;
  }
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  vertical-align: top;
`;

const TileButtonIcon = function tileButtonIconGetter(iconUrl) {
  return function tileButtonIcon(props) {
    return (
      <Icon {...props}>
        <Img src={iconUrl} />
      </Icon>
    );
  };
};

const ServicesPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const { title } = data.markdownRemark.frontmatter;

  return (
    <>
      <Hat pageName={title} />
      <Layout>
        <Breadcrumb crumbs={crumbs} />
        <SectionContainer>
          <Content>
            <SectionTitle align="left">{title}</SectionTitle>
            <TileButtons>
              {data.allMarkdownRemark.edges.map(
                ({
                  node: {
                    frontmatter,
                    fields: { slug },
                  },
                }) => (
                  <LinkWrapper to={slug} key={slug} underline="none">
                    <TileButton
                      Icon={TileButtonIcon(frontmatter.icon.publicURL)}
                      caption={frontmatter.title}
                      description={frontmatter.description}
                    />
                  </LinkWrapper>
                )
              )}
            </TileButtons>
          </Content>
        </SectionContainer>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { id: { eq: "services-section" } }) {
      frontmatter {
        title
        subtitle
        moreAboutServicesButtonText
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/sluzby/)/" } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            icon {
              publicURL
            }
            description
          }
        }
      }
    }
  }
`;

export default ServicesPage;
